import React, { useState, useEffect } from 'react';
import { authEDURequest } from '../store/Api';
import Loading from './atoms/Loading';
import CenteredLayout from './layouts/CenteredLayout';
import NotFoundOrigin from './NotFoundOrigin';
import { useGetCompany } from '../react-query/general';

const WrapperComponent = ({ children }) => {
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { dataCompany, loadingDataCompany, errorDataCompany } = useGetCompany();


  useEffect(() => {
    checkDomain();
  }, []);

  useEffect(()=> {
    if(dataCompany){
      document.title = dataCompany[0]?.company_name ?? "";
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = dataCompany[0].file_path_logo ?? ''; // Replace URL_TO_YOUR_FAVICON with the actual URL to your favicon
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  },[dataCompany])

  const checkDomain = async () => {
    try {
      // Make the API request
      const response = await authEDURequest.get('/api/cek-domain');
      const responseCheckDomain = response.status;

      setApiResponse(responseCheckDomain);
      setLoading(false);
    } catch (error) {
      // Handle any errors that occurred during the request
      setLoading(false);
      setError(error);
    }
  };

  if (loading) {
    return (
      <CenteredLayout>
        <Loading />
      </CenteredLayout>
    );
  }

  if (error || apiResponse == null) {
    return <NotFoundOrigin />;
  }

  if (apiResponse && apiResponse !== 409) {
    return <div>{children}</div>;
  }
};

export default WrapperComponent;

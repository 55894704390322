import { useQuery } from '@tanstack/react-query';
import {
  fetchRepos,
  fetchBanner,
  fetchDataCompany,
  fetchFeatures,
  fetchTestimoni,
  fetchProgrammes,
  fetchAbout,
  fetchHelp,
  fetchPrivacy,
  fetchRefund,
  fetchTerms,
  fetchCancellation,
  fetchFaq,
  fetchCompanyLogo} from "./ApiRequest";

export const useGetRepos = () => {
  const {
    data,
    isLoading: loadingRepos,
    isError: errorRepos,
  } = useQuery(['repo'], fetchRepos);

  return { data, loadingRepos, errorRepos };
};

export const useGetBanner = () => {
  const {
    data: listBanner,
    isLoading: loadingBanner,
    error: errorBanner,
  } = useQuery(['banner'], fetchBanner);

  return { listBanner, loadingBanner, errorBanner };
};

export const useGetCompany = () => {
  const {
    data: dataCompany,
    isLoading: loadingDataCompany,
    isError: errorDataCompany,
  } = useQuery(['general'], fetchDataCompany);

  return { dataCompany, loadingDataCompany, errorDataCompany };
};

export const useGetCompanyLogo = () => {
  const { data: companyLogo, isLoading : loadingCompanyLogo, isError : errorCompanyLogo } = useQuery(['logo'], fetchCompanyLogo);

return {companyLogo, loadingCompanyLogo, errorCompanyLogo }
}

export const useGetFeatures = () => {
  const {
    data: features,
    isLoading: loadingFeatures,
    isError: errorFeatures,
  } = useQuery(['features'], fetchFeatures);

  return { features, loadingFeatures, errorFeatures };
};

export const useGetTestimoni = () => {
  const {
    data: testimonies,
    isLoading: loadingTestimonies,
    isError: errorTestimonies,
  } = useQuery(['testimonies'], fetchTestimoni);

  return { testimonies, loadingTestimonies, errorTestimonies };
};

export const useGetProgrammes = () => {
  const {
    data: programmes,
    isLoading: loadingProgrammes,
    isError: errorProgrammes,
  } = useQuery(['programmes'], fetchProgrammes);

  return { programmes, loadingProgrammes, errorProgrammes };
};

export const useGetAbout = () => {
  const {
    data: about,
    isLoading: loadingAbout,
    isError: errorAbout,
  } = useQuery(['about'], fetchAbout);

  return { about, loadingAbout, errorAbout };
};

export const useGetHelp = () => {
  const {
    data: help,
    isLoading: loadingHelp,
    isError: errorHelp,
  } = useQuery(['help'], fetchHelp);

  return { help, loadingHelp, errorHelp };
};

export const useGetFaq = () => {
  const {
    data: faq,
    isLoading: loadingFaq,
    isError: errorFaq,
  } = useQuery(['faq'], () => fetchFaq());

  return { faq, loadingFaq, errorFaq };
};

export const useGetTerms = () => {
  const {
    data: terms,
    isLoading: loadingTerms,
    isError: errorTerms,
  } = useQuery(['terms'], fetchTerms);

  return { terms, loadingTerms, errorTerms };
};

export const useGetPrivacy = () => {
  const {
    data: privacy,
    isLoading: loadingPrivacy,
    isError: errorPrivacy,
  } = useQuery(['privacy'], fetchPrivacy);

  return { privacy, loadingPrivacy, errorPrivacy };
};

export const useGetRefund = () => {
  const {
    data: refund,
    isLoading: loadingRefund,
    isError: errorRefund,
  } = useQuery(['refund'], fetchRefund);

  return { refund, loadingRefund, errorRefund };
};

export const useGetCancellation = () => {
  const {
    data: cancellation,
    isLoading: loadingCancellation,
    isError: errorCancellation,
  } = useQuery(['refund'], fetchCancellation);

  return { cancellation, loadingCancellation, errorCancellation };
};
